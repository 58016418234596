const services = [
  {
    imgSrc: "/images/canada10.jpg",
    title: "Refugee Claims",
    link: "/services/refugee-claims",
  },
  {
    imgSrc: "/images/canada13.jpg",
    title: "Refugee Appeal",
    link: "/services/refugee-appeal",
  },
  {
    imgSrc: "/images/canada16.jpg",
    title: "Pre-Removal Risk Assessment",
    link: "/services/pre-removal-risk-assessment",
  },
  {
    imgSrc: "/images/canada8.jpg",
    title: "Humanitarian and Compassionate Consideration (H&C)",
    link: "/services/humanitarian-and-compassionate",
  },
  {
    imgSrc: "/images/canada17.jpg",
    title: "Detention Reviews",
    link: "/services/detention-reviews",
  },
  {
    imgSrc: "/images/canada19.jpg",
    title: "Express Entry",
    link: "/services/express-entry",
  },
  {
    imgSrc: "/images/canada18.jpg",
    title: "Family Sponsorship",
    link: "/services/family-sponsorship",
  },

  {
    imgSrc: "/images/canada7.jpg",
    title: "Other Services",
    link: "/services/other-services",
  },
];

export default services;
