import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "../Components/Footer";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

const PayNow = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const item = {
    price: "price_1PZfppAzX3LhXgfPfKv8fkki",
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`,
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);
  return (
    <div>
      <div className="relative hero-container">
        <img
          src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/canad12_x0rjzp"
          loading="lazy"
          className="hero-image"
          alt="Hero"
        />
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
          <h1 className="text-4xl font-bold text-center">
            PAY <span className="text-primary">NOW</span>
          </h1>
          <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
          <p className="text-xl text-center">AbaNorbert Immigration Services</p>
        </div>
      </div>

      <section className="bg-white text-gray-800 py-10">
        <div className="container grid grid-cols-1 mx-auto lg:grid-cols-2 items-center">
          <div className="p-4 lg:flex justify-center">
            <img
              src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/stripe_bwa2ec"
              alt="Stripe Image"
              className="w-48 h-36 lg:ml-60"
            />
          </div>

          <div className="p-4">
            <h2 className="text-xl font-bold mb-4 text-primary">PAY NOW</h2>
            <p className="mb-8 text-md">
              If you would like to pay the installment amount online;
              <br />
              please use the following link, indicate your name, your email, and
              select the payment amount. If you are paying on behalf of someone
              else, please add the client’s name in the appropriate field.
            </p>
            <button
              className="self-start px-6 py-2 text-lg font-medium bg-primary text-white"
              onClick={redirectToCheckout}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Pay now"}
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PayNow;