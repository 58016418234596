import React from "react";

const Hero = () => {
  return (
    <div className="relative hero-container">
      <img
        src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/canad12_x0rjzp"
        loading="lazy"
        className="hero-image"
      />
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
        <h1 className="text-4xl font-bold text-center">
          ABOUT <span className="text-primary">US</span>
        </h1>
        <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
        <p className="text-xl text-center">
          About AbaNorbert Immigration Services
        </p>
      </div>
    </div>
  );
};

export default Hero;