import React from "react";
import { InlineWidget } from "react-calendly";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Calendly = () => {
  return (
    <div>
      <NavBar />
      <section>
        <div className="relative hero-container">
          <img
            src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/canad12_x0rjzp"
            loading="lazy"
            className=" hero-image"
          />
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
            <h1 className="text-5xl font-bold text-center">
              Book <span className="text-primary">Appointment</span>
            </h1>
            <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
            <p className="text-xl text-center">
              Take your first step to your immigration journey
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="App">
          <InlineWidget url="https://calendly.com/abanorbert" />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Calendly;
